import React, {
  FunctionComponent,
  PureComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./Graph.scss";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { useStateValue } from "../../../../../util/StateProvider";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { Loader } from "svg-loader-react";
function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const labels: any = [];

const MDSSloadData = [, , , , , , 5];

const AutoData = [1, 6, 3, 4];
for (let index = 1; index < 30; index++) {
  const element = "Nov " + index + ", 2022";
  // let incom = randomIntFromInterval(0, 8);
  // let subfdata = randomIntFromInterval(0, 8);
  // let mdssfdata = randomIntFromInterval(0, 8);
  // let mdssLoad = randomIntFromInterval(0, 8);
  // let patientfdata = randomIntFromInterval(0, 8);
  // let autofdata = randomIntFromInterval(0, 8);
  // Incompleteata.push(incom);
  // InvestSubData.push(subfdata);
  // MMdssData.push(mdssfdata);
  // MDSSloadData.push(mdssLoad);
  // PatientSub.push(patientfdata);
  // AutoData.push(autofdata);
  labels.push(element);
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const arbitraryStackKey = "stack1";

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: "Montserrat",
        },
      },
    },
  },
  responsive: true,

  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Form Sent Date",
      position: "bottom" as const,
      font: {
        family: "Montserrat",
      },
    },
    legend: {
      cursor: "pointer",

      position: "bottom" as const,
      labels: {
        usePointStyle: true,
        // boxWidth: 30,
        font: {
          family: "Montserrat",
        },
      },
      tooltip: {
        bodyFont: {
          family: "Montserrat",
        },
        titleFont: {
          family: "Montserrat",
        },
      },
    },
  },
};
// const monthName = (item: any) =>
// moment(item.created_at, "YYYY-MM-DD").format("MMM");
const Graph = (graphData: any) => {
  const [state, dispatch] = useStateValue();
  const [dateRange, setDateRange] = useState();
  const [Incomplete, setIncomplete] = useState<any>();
  const [PatienSub, setPatienSub] = useState<any>();
  const [investSub, setInvestSub] = useState<any>();
  const [ManagedMDSS, setManagedMDSS] = useState<any>();
  const [autoTransmit, setAutoTransmit] = useState<any>();
  const [MdssLoaded, setMdssLoaded] = useState<any>();
  const [graphLables, setGraphLables] = useState<any>();

  const graphD = useMemo(() => {
    return {
      labels: graphLables,
      datasets: [
        {
          barThickness: 8,
          label: "Incomplete",
          data: Incomplete,
          stack: arbitraryStackKey,
          backgroundColor: "#dc5e5e",
        },
        {
          barThickness: 8,
          label: "Invest Sub",
          data: investSub,
          backgroundColor: "#00b2b9",
          stack: arbitraryStackKey,
        },
        {
          barThickness: 8,
          label: "Managed In MDSS",
          data: ManagedMDSS,
          backgroundColor: "#DDDDDD",
          stack: arbitraryStackKey,
        },
        {
          barThickness: 8,
          label: "MDSS Loaded",
          data: MdssLoaded,
          backgroundColor: "#323031",
          stack: arbitraryStackKey,
        },
        {
          barThickness: 8,
          label: "Patient Sub",
          data: PatienSub,
          backgroundColor: "#FDB933",
          stack: arbitraryStackKey,
        },
        {
          barThickness: 8,
          label: "Auto Transmit",
          data: autoTransmit,
          backgroundColor: "#B2E8EA",
          stack: arbitraryStackKey,
        },
      ],
    };
  }, [Incomplete, PatienSub, ManagedMDSS, autoTransmit, MdssLoaded]);
  useEffect(() => {
    // for (let i = 0; i < dateRange.length; i++) {
    //   dateRange[i] = format(dateRange[i], "LLL d,yyyy");
    // }

    let groupedResult = _.groupBy(graphData.graphData.allData, (item) => {
      return item.Form_Sent_Date.value;
    });

    let fullData: any = {};
    const datesArray: any = [];
    let LocalIncomplete = [];
    let LocalPatientSub = [];
    let LocalManagedMDSS = [];
    let LocalInvestSub = [];
    let LocalMdssLoaded = [];
    let LocalFormRecipient = [];
    let LocalAutoTransmitt = [];
    let totalCount = 0;
    let incompleteCount = 0;
    let investsubCount = 0;
    let MdssLoadedCount = 0;
    let ManagedMdssCount = 0;
    let patientSubCount = 0;
    let autoTransmitCount = 0;
    let FormRecipientsCount = 0;
    if (Object.keys(groupedResult).length > 0) {
      Object.keys(groupedResult).map((Inddate) => {
        datesArray.push(new Date(Inddate));
        const nowData = _.groupBy(groupedResult[Inddate], (item) => {
          return item.Form_Status;
        });
        fullData[Inddate] = nowData;
      });
      datesArray.sort(function (a: any, b: any) {
        return a - b;
      });
      let dateRange: any;
      console.log(state.formDate.length);
      if (state.formDate.length === 2) {
        dateRange = eachDayOfInterval({
          start: parseISO(state.formDate[0]),
          end: parseISO(state.formDate[1]),
        });
      } else {
        dateRange = eachDayOfInterval({
          start: datesArray[0],
          end: datesArray[datesArray.length - 1],
        });
      }

      let graphLables = [];
      for (let i = 0; i < dateRange.length; i++) {
        graphLables.push(format(dateRange[i], "LLL d, yyyy"));
      }
      setDateRange(dateRange);
      setGraphLables(graphLables);

      for (let i = 0; i < dateRange.length - 1; i++) {
        let tempFormRecipients = 0;

        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.Incompleted) {
          LocalIncomplete.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.Incompleted?.length
          );

          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.Incompleted.length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.Incompleted[index][
                "Message_Status"
              ] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }

          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")].Incompleted.length;
          incompleteCount =
            incompleteCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")].Incompleted.length;
        } else {
          LocalIncomplete.push(undefined);
        }

        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"]) {
          //
          LocalPatientSub.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"].length
          );
          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"]
              .length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"][
                index
              ]["Message_Status"] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }

          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"]
              .length;
          patientSubCount =
            patientSubCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Patient Sub"]
              .length;
        } else {
          LocalPatientSub.push(undefined);
        }
        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"]) {
          //
          LocalManagedMDSS.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"]
              .length
          );
          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"]
              .length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"][
                index
              ]["Message_Status"] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }
          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"]
              .length;
          ManagedMdssCount =
            ManagedMdssCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Managed in MDSS"]
              .length;
        } else {
          LocalManagedMDSS.push(undefined);
        }
        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"]) {
          //
          LocalInvestSub.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"].length
          );
          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"].length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"][
                index
              ]["Message_Status"] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }
          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"].length;
          investsubCount =
            investsubCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Invest Sub"].length;
        } else {
          LocalInvestSub.push(undefined);
        }
        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"]) {
          LocalAutoTransmitt.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"]
              .length
          );
          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"]
              .length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"][
                index
              ]["Message_Status"] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }
          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"]
              .length;
          autoTransmitCount =
            autoTransmitCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["Auto Transmit"]
              .length;
        } else {
          LocalAutoTransmitt.push(undefined);
        }
        if (fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"]) {
          //
          LocalMdssLoaded.push(
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"].length
          );
          for (
            let index = 0;
            index <
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"]
              .length;
            index++
          ) {
            if (
              fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"][
                index
              ]["Message_Status"] === "Delivered"
            ) {
              tempFormRecipients = tempFormRecipients + 1;
            }
          }
          totalCount =
            totalCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"]
              .length;
          MdssLoadedCount =
            MdssLoadedCount +
            fullData[format(dateRange[i], "yyyy-MM-dd")]?.["MDSS Loaded"]
              .length;
        } else {
          LocalMdssLoaded.push(undefined);
        }

        LocalFormRecipient.push(tempFormRecipients);
        FormRecipientsCount = FormRecipientsCount + tempFormRecipients;
      }

      setAutoTransmit(LocalAutoTransmitt);
      setIncomplete(LocalIncomplete);
      setPatienSub(LocalPatientSub);
      setManagedMDSS(LocalManagedMDSS);
      setInvestSub(LocalInvestSub);
      setMdssLoaded(LocalMdssLoaded);
      //

      dispatch({
        type: "SetToatalCount",
        totalCount: totalCount,
      });
      dispatch({
        type: "SetAutoTransmitCount",
        autoTransmitCount: autoTransmitCount,
      });
      dispatch({
        type: "SetFormRecipientsCount",
        FormRecipientsCount: FormRecipientsCount,
      });
      dispatch({
        type: "SetManagedMdssCount",
        ManagedMdssCount: ManagedMdssCount,
      });
      dispatch({
        type: "SetincompleteCount",
        incompleteCount: incompleteCount,
      });
      dispatch({
        type: "SetPatientSubCount",
        patientSubCount: patientSubCount,
      });
      dispatch({
        type: "SetMdssLoadedCount",
        MdssLoadedCount: MdssLoadedCount,
      });
      dispatch({
        type: "SetInvestsubCount",
        investsubCount: investsubCount,
      });
    } else {
      setGraphLables([]);
      setIncomplete([]);
      setPatienSub([]);
      setManagedMDSS([]);
      setInvestSub([]);
      setMdssLoaded([]);
      setAutoTransmit([]);
    }
  }, [state.formDate, graphData.graphData.allData]);

  return (
    <div className="barGraph">
      {graphData.graphData.isTableLoading ? (
        <div className="spinner">
          <Loader name={"audio"} height={40} width={40} color={"#fdb933"} />
        </div>
      ) : (
        <Bar options={options} data={graphD} />
      )}
    </div>
  );
};

export default Graph;
