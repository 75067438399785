import axios from "axios";

export function excelExport() {
  return axios.get(`/api/downloadschedule`, {
    responseType: "arraybuffer",
  });
}
export async function exportTableData(data: any): Promise<any> {
  try {
    const res = await axios.post("/exportTable", data, {
      responseType: "arraybuffer",
    });
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        
        return resolve(res);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
