import axios from "axios";

export async function sendJotFormLink(record: any): Promise<any> {
  try {
    var config = {
      method: "post",
      url: "/api/resendJotform",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        data: {
          MobilePhone: record.Phone,

          PatientName: record.PatientName,

          FormSent: record.Form_Sent_Date.value,

          PatientID: record.Patient_id,

          CaseJurisdiction: record.Jurisdiction_Code,

          InvestigatorEmail: record.InvestigatorEmail,

          JotformLink: record.Jot_Form,

          DateOfBirth: record.DOB.value,

          StateInvestigatorID: record.Investigator_Id,

          MDSS_ID: record.MDSS_Id,
        },
      },
    };
    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
