import { useEffect, useState } from "react";
import "./Dashboard.scss";
import Filters from "./filters/Filters";
import Header from "./header/Header";
import TableGraph from "./tablesAndGraphs/TableGraph";
const Dashboard = () => {
  const [offset, setOffset] = useState(0);

    useEffect(() => {
        // const onScroll = () => setOffset(window.pageYOffset);
        // // clean up code
        // window.removeEventListener('scroll', onScroll);
        // window.addEventListener('scroll', onScroll, { passive: true });
        
        // return () => window.removeEventListener('scroll', onScroll);
    }, []);
  return (
    <div  className="dashboardGrid">
      <div className="logo"><Header/></div>
      <div className={"header "+ offset}><Filters/></div>
      <div className="tableAndGraph" ><TableGraph/></div>
     
    </div>
  );
};

export default Dashboard;
