import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";
export const initialState = {
  token: null,
  mdssIdFilter: [],
  patientNameFilter: [],
  TableInvestigatorFilter: [],
  investigatorFilter: [],
  mdssIdFilterValues: [],
  patientNameFilterValues: [],
  investigatorFilterValues: [],
  totalCount: 0,
  ManagedMdssCount: 0,
  incompleteCount: 0,
  patientSubCount: 0,
  MdssLoadedCount: 0,
  investsubCount: 0,
  autoTransmitCount: 0,
  FormRecipientsCount: 0,
  removeSelected: false,
  currentUser: "",
  formDate: [
    format(startOfWeek(new Date()), "yyyy-MM-dd"),
    format(endOfWeek(new Date()), "yyyy-MM-dd"),
  ],
  searchQuerry: {
    Form_Sent_Date: [
      format(startOfWeek(new Date()), "yyyy-MM-dd"),
      format(endOfWeek(new Date()), "yyyy-MM-dd"),
    ],
  },
  ClickedFilter: "",
  JurisdictionFilter: [],
  selectedRows: [],
  reset: false,
  authenticted: false,
  spin: true,
  userData: {},
  applyFilter: false,
  refresh: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SetSearchQuerry": {
      return {
        ...state,
        searchQuerry: action.searchQuerry,
      };
    }
    case "SETUSER": {
      return {
        ...state,
        currentUser: action.currentUser,
      };
    }
    case "SETTableInvestigatorFilter": {
      return {
        ...state,
        TableInvestigatorFilter: action.TableInvestigatorFilter,
      };
    }
    case "SetToatalCount": {
      return {
        ...state,
        totalCount: action.totalCount,
      };
    }
    case "SetManagedMdssCount": {
      return {
        ...state,
        ManagedMdssCount: action.ManagedMdssCount,
      };
    }
    case "SetAutoTransmitCount": {
      return {
        ...state,
        autoTransmitCount: action.autoTransmitCount,
      };
    }
    case "SetFormRecipientsCount": {
      return {
        ...state,
        FormRecipientsCount: action.FormRecipientsCount,
      };
    }
    case "SetincompleteCount": {
      return {
        ...state,
        incompleteCount: action.incompleteCount,
      };
    }
    case "SetPatientSubCount": {
      return {
        ...state,
        patientSubCount: action.patientSubCount,
      };
    }
    case "SetMdssLoadedCount": {
      return {
        ...state,
        MdssLoadedCount: action.MdssLoadedCount,
      };
    }
    case "SetInvestsubCount": {
      return {
        ...state,
        investsubCount: action.investsubCount,
      };
    }
    case "SetApplyFilter": {
      return {
        ...state,
        applyFilter: action.applyFilter,
      };
    }
    case "SETMDSSVALUE": {
      return {
        ...state,
        mdssIdFilterValues: action.mdssIdFilterValues,
      };
    }
    case "SETPatientNameValue": {
      return {
        ...state,
        patientNameFilterValues: action.patientNameFilterValues,
      };
    }
    case "SetFormDateValues": {
      return {
        ...state,
        formDate: action.formDate,
      };
    }
    case "setRemovedSelected": {
      return {
        ...state,
        removeSelected: action.removeSelected,
      };
    }
    case "SETInvestigatorFilterValues": {
      return {
        ...state,
        investigatorFilterValues: action.investigatorFilterValues,
      };
    }
    case "SETTOKEN": {
      return {
        ...state,
        token: action.token,
      };
    }
    case "SETREFRESH": {
      return {
        ...state,
        refresh: action.refresh,
      };
    }
    case "SETUSERDATA": {
      return {
        ...state,
        userData: action.userData,
      };
    }
    case "SETSPIN": {
      return {
        ...state,
        spin: action.spin,
      };
    }
    case "SETAUTH": {
      return {
        ...state,
        authenticted: action.authenticted,
      };
    }
    case "SETRESET": {
      return {
        ...state,
        reset: action.reset,
      };
    }
    case "SETSELECTEDROWS": {
      return {
        ...state,
        selectedRows: action.selectedRows,
      };
    }

    case "setMdssFilter": {
      return {
        ...state,
        mdssIdFilter: action.mdssIdFilter,
      };
    }
    case "setPatientFilter": {
      return {
        ...state,
        patientNameFilter: action.patientNameFilter,
      };
    }
    case "setInvestigatorFilter": {
      return {
        ...state,
        investigatorFilter: action.investigatorFilter,
      };
    }
    case "setClickedFilter": {
      return {
        ...state,
        ClickedFilter: action.ClickedFilter,
      };
    }

    case "setJurisdictionFilter": {
      return {
        ...state,
        JurisdictionFilter: action.JurisdictionFilter,
      };
    }

    default:
      return state;
  }
};
export default reducer;
