import React from "react";
import "./Header.scss";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "antd";

const Header = () => {
  return (
    <div className="headerClass">
      <div className="logo">
        <img
          className="img"
          src={require("../../../common/images/PeopleHealth.png")} width="165px"
        />
      </div>
      <div className="text">
        {/* <Tooltip title="How-To Guide">
          <a
            className="help"
            target={"_blank"}
            href="https://stonly.com/sl/6c806e3d-d717-44f3-a3ea-9eb278d22f12/Steps/"
          >
            <FaQuestionCircle color="#00b2b9" size={24} />
          </a>
        </Tooltip> */}
        COVID-19 CASE MANAGEMENT DASHBOARD
      </div>
    </div>
  );
};

export default Header;
