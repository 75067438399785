import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import bgPattern from "../../assests/loading-bg.jpg"
import quotes from "../../assests/quotes";
import "./Form.scss"

const dotStyle = {
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: "black",
  animation: "dotFlashing 2s infinite both",
};

const Form = () => {
  const [paramsAdded, setParamsAdded] = useState(false);
  const [paramsString, setParamsString] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const [quote, setQuote] = useState<string>("");
  const [author, setAuthor] = useState<string>("");

  useEffect(() => {
    // Function to get a random quote
    //fixing
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    console.log(quotes,"quotes")
    setQuote(randomQuote.text); 
    setAuthor(randomQuote.author)// Update state with random quote
  }, []); // This will run only on component mount (refresh)


  const handleDecrypt = (encryptedToken: string) => {
    console.log(encryptedToken)
    const bytes = CryptoJS.AES.decrypt(atob(encryptedToken), 'M!r@Cl$@5o7');
    console.log(bytes)
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
  useEffect(() => {
    const fetchParams = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const encryptedToken = urlParams.get("token");
        console.log("encryptedToken")
  
        if (encryptedToken && encryptedToken.length > 1) {
          const token = handleDecrypt(encryptedToken);
          console.log("Decrypted Token:", token);  // Log decrypted token for debugging
          urlParams.delete("token");
          const newUrl = `${location.pathname}?${urlParams.toString()}`;
          window.history.replaceState(null, '', newUrl);
          const submissionID = urlParams.get("submissionID");
  
          const response = await fetch(`/api/getPreviousSubmission`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ submissionID }),
          });
  
          if (!response.ok) {
            throw new Error(`Server error: ${response.status}`);
          }
  
          const data = await response.json();
          console.log(data, "------data");
  
          const queryObj = data.data.queryObj;
          let queryStr = window.location.search;
          for (let key in queryObj) {
            queryStr += `&${key}=${queryObj[key]}`;
          }
          setParamsString(queryStr);
          setParamsAdded(true);
        }
      } catch (error) {
        console.error("Error during fetchParams:", error);
      }
    };
  
    fetchParams();
  }, []);

  /*useEffect(() => {
        const fetchHtmlContent = async () => {
            try {
                fetch(`/jotform.html`).then((response) => response.text()).then((html) => {
                    setHTMLContent(html)
                })
            } catch (error) {
                console.error(error)
            }
        }

        if (paramsAdded) {
            fetchHtmlContent()
        }
    }, [paramsAdded])*/

  // if (!paramsAdded) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", height: "100vh",backgroundColor:"#fbdcb4" }}>
  //       <img
  //         src={loadingGif}
  //         alt="Loading..."
  //         style={{ width: '50%', height: '80%', alignItems: "Center", justifyContent: "center",borderWidth:1,borderColor:"white",marginBottom:100,borderRadius:"15%",zIndex:1000 }} // Adjust size as needed
  //       />
  //     </div>
  //   );
  // }

  if (!paramsAdded) {
    return (
      <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "100vh",
        backgroundImage: `url(${bgPattern})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          flexDirection: "column",
          width: "19%",
          height: "45%",
          borderRadius: "50%",
          backgroundColor: "white",
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3), 0px -8px 15px rgba(0, 0, 0, 0.3)",
          position: "relative",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "30px",
        }}
      >
        <p className="quoteText">{quote}</p>
        <p style={{ fontWeight: "bolder", marginBottom: "10px" }}>{author}</p>
    
        {/* Loading Animation */}
        <div style={{ display: "flex", gap: "6px"}}>
          <div className="dot" style={dotStyle}></div>
          <div className="dot" style={dotStyle}></div>
          <div className="dot" style={dotStyle}></div>
          <div className="dot" style={dotStyle}></div>
          <div className="dot" style={dotStyle}></div>
        </div>
      </div>
    </div>
    

      /* <img
        src={loadingGif}
        alt="Loading..."
        style={{ width: '30%', height: '50%', alignItems: "Center", justifyContent: "center" }} // Adjust size as needed
      /> */
    );
  }


  return (
    <iframe
      src={`/jotform.html${paramsString}`}
      style={{ width: "100%", height: "100vh", border: "none" }}
    ></iframe>
  );
};

export default Form;
