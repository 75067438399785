const quotes: string[] = [
    "A healthy outside starts from the inside. – Robert Urich",
    "If you keep good food in your fridge, you will eat good food. – Errick McAdams",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "There is no health without mental health; mental health is too important to be left to the professionals alone, and mental health is everyone’s business. – Vikram Patel",
    "Those who have no time for healthy eating will sooner or later have to find the time for illness. – Edward Stanley",
    "What most people don’t realize is that food is not just calories: It’s information. It actually contains messages that communicate to every cell in the body. – Dr. Mark Hyman",
    "Sorry, there’s no magic bullet. You gotta eat healthy and live healthy to be healthy and look healthy. End of story. – Morgan Spurlock",
    "Good health is not something we can buy. However, it can be an extremely valuable savings account. – Anne Wilson Schaef",
    "Our bodies are our gardens, our wills are our gardeners. – William Shakespeare",
    "Self-care is not selfish. You cannot serve from an empty vessel. – Eleanor Brown"
  ];
  
  // Function to split the quote into text and author
  const splitQuote = (quote: string) => {
    const parts = quote.split(" – ");
    return {
      text: parts[0],
      author: parts[1] || "Unknown"
    };
  };
  
  // Apply the splitQuote function to all quotes
  const formattedQuotes = quotes.map(splitQuote);
  
  export default formattedQuotes;
  