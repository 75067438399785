import "./TableGraph.scss";
import "antd/dist/antd.css";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Select, Tabs, Tag, Tooltip } from "antd";
import fileSaver from "file-saver";
import {
  FaTable,
  FaChartBar,
  FaDownload,
  FaSync,
  FaQuestionCircle,
} from "react-icons/fa";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import AntTable from "./table/AntTable";
import GraphGrid from "./graphGrid/GraphGrid";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../util/StateProvider";
import { getAllTableData, refreshData } from "../../../services/tableFind";
import groupBy from "lodash/groupBy";
import { MultiManagedInMdssApi } from "../../../services/managedInMdss";
import { Loader } from "svg-loader-react";
import { GetFilters } from "../../../services/filtersDrop";
import { ChangeBulkInvestigator } from "../../../services/investigator";
import { exportTableData } from "../../../services/export";
import { differenceInDays, eachDayOfInterval, parseISO } from "date-fns";
const { TabPane } = Tabs;
const { Option } = Select;
const TableGraph = () => {
  const [getStateValue, dispatch] = useStateValue();
  const [showAllManageCase, setShowAllManageCase] = useState(false);
  const [showInvestigator, setShowInvestigator] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [inProgress, setInprogress] = useState(false);
  const [isTableDataLoading, setisTableDataLoading] = useState(true);
  const [position, SetPosition] = useState<any>(["right"]);
  const [NewInvestigatorID, setNewInvestigatorID] = useState<any>();
  const [isItFirst, setFirst] = useState(true);
  const [investigatorDetails, setInvestigatorDetails] = useState<any>();
  const [currentCase, setCurrentCase] = useState<any>("ManagedMdss");
  // useEffect(() => {
  //   if (getStateValue.selectedRows?.length > 0) {
  //     SetPosition(["left", "right"]);
  //   }
  // }, [getStateValue.selectedRows]);

  const OperationsSlot: any = {
    right: (
      <div className="ExtraButtons">
        {activeKey === "1" ? (
          <>
            {getStateValue.selectedRows?.length > 0 ? (
              <>
                <Tooltip title="How-To Guide">
                  <a
                    style={{ display: "flex" }}
                    className="help"
                    target={"_blank"}
                    href="https://stonly.com/sl/6c806e3d-d717-44f3-a3ea-9eb278d22f12/Steps/"
                  >
                    <FaQuestionCircle color="#00b2b9" size={24} />
                  </a>
                </Tooltip>
                <Button
                  onClick={() => setShowAllManageCase(true)}
                  className="tabButtons"
                >
                  Managed in MDSS
                </Button>
                <Button
                  onClick={() => setShowInvestigator(true)}
                  className="tabButtons"
                  disabled={
                    investigatorDetails
                      ? investigatorDetails.length > 0
                        ? false
                        : true
                      : true
                  }
                  style={{
                    cursor: investigatorDetails
                      ? investigatorDetails.length > 0
                        ? "pointer"
                        : "progress"
                      : "progress",
                  }}
                >
                  Change Investigator
                </Button>
                {inProgress ? (
                  <Loader
                    name={"spiningCircle"}
                    height={25}
                    width={25}
                    color={"#00b2b9"}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Tooltip title="How-To Guide">
                <a
                  style={{ display: "flex" }}
                  className="help"
                  target={"_blank"}
                  href="https://stonly.com/sl/6c806e3d-d717-44f3-a3ea-9eb278d22f12/Steps/"
                >
                  <FaQuestionCircle color="#00b2b9" size={24} />
                </a>
              </Tooltip>
            )}
            <div className="ExtraButtons Icons">
              <Tooltip placement="bottom" title="Download">
                <span className="Tabicon">
                  <FaDownload
                    onClick={() => {
                      exportTableData({ filterresult: tableData }).then(
                        (res) => {
                          var blob = new Blob([res.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          });
                          fileSaver.saveAs(blob, "tableData.xlsx");
                        }
                      );
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip placement="bottom" title="Refresh">
                <span className="Tabicon">
                  <FaSync
                    onClick={() =>
                      dispatch({
                        type: "SETREFRESH",
                        refresh: !getStateValue.refresh,
                      })
                    }
                  />
                </span>
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "left", flex: 1 }}>
              <Tooltip title="How-To Guide">
                <a
                  style={{ display: "flex" }}
                  className="help"
                  target={"_blank"}
                  href="https://stonly.com/sl/6c806e3d-d717-44f3-a3ea-9eb278d22f12/Steps/"
                >
                  <FaQuestionCircle color="#00b2b9" size={24} />
                </a>
              </Tooltip>
            </div>

            <Tag color={"#757575"} className="tags">
              Total count: {getStateValue.totalCount}
            </Tag>
            <Tag color={"#2BDCE3"} className="tags">
              Form Recipients: {getStateValue.FormRecipientsCount}
            </Tag>
            <Tag color={"#FDB933"} className="tags">
              Patient Sub: {getStateValue.patientSubCount}
            </Tag>
            <Tag color={"#00b2b9"} className="tags">
              Invest Sub: {getStateValue.investsubCount}
            </Tag>
            <Tag color={"#dc5e5e"} className="tags">
              Incomplete: {getStateValue.incompleteCount}
            </Tag>
            <Tag color={"#323031"} className="tags">
              MDSS Loaded: {getStateValue.MdssLoadedCount}
            </Tag>
            <Tag color={"#DDDDDD"} className="tags" style={{ color: "black" }}>
              Managed in MDSS: {getStateValue.ManagedMdssCount}
            </Tag>
            <Tag color={"#B2E8EA"} className="tags" style={{ color: "black" }}>
              Auto Transmit: {getStateValue.autoTransmitCount}
            </Tag>
          </>
        )}
      </div>
    ),
  };
  const slot = React.useMemo(() => {
    return position.reduce(
      (acc: any, direction: string | number) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [
    getStateValue.selectedRows,
    activeKey,
    getStateValue.totalCount,
    inProgress,
    tableData,
    investigatorDetails,
  ]);
  const handleOk = () => {
    setShowAllManageCase(false);
    setShowInvestigator(false);
  };
  const bulkChangeInvestigator = (selectedRows: any) => {
    let newData = selectedRows.map((eachRecord: any) => {
      return {
        MDSS_ID: eachRecord.MDSS_Id,

        Patient_ID: eachRecord.Patient_id,

        Changedby: getStateValue.currentUser.Investigator_email,

        Old_InvestigatorID: eachRecord.Investigator_Id,

        Jurisdiction_Old: eachRecord.Orginal_Jurisdiction,

        New_InvestigatorID: NewInvestigatorID,
      };
    });

    ChangeBulkInvestigator({ ChangeInvestigator: newData }).then((res) => {
      setInprogress(false);
      toast("Successfully changed investigator.", {
        position: "top-right",
        theme: "dark",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: "setRemovedSelected",
        removeSelected: !getStateValue.removeSelected,
      });
      dispatch({
        type: "SETREFRESH",
        refresh: !getStateValue.refresh,
      });
    });
  };
  const handleCaseStatus = (records: any) => {
    if (currentCase === "ManagedMdss") {
      setShowAllManageCase(false);
      setShowInvestigator(false);
      let MDSSID = records.map((record: any) => {
        if (
          record.Form_Status === "Incomplete" ||
          record.Form_Status === "Patient Sub"
        )
          return record.MDSS_Id;
        else return "";
      });

      MultiManagedInMdssApi(MDSSID)
        .then((res) => {
          setInprogress((p) => false);

          toast("Successfully changed to Managed in MDSS.", {
            position: "top-right",
            theme: "dark",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "setRemovedSelected",
            removeSelected: !getStateValue.removeSelected,
          });
          dispatch({
            type: "SETREFRESH",
            refresh: !getStateValue.refresh,
          });
        })
        .catch((e) => {
          setInprogress((p) => false);
          toast("Case status can’t be modified.", {
            position: "top-right",
            theme: "dark",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "setRemovedSelected",
            removeSelected: !getStateValue.removeSelected,
          });
        });
    }
  };
  const isAnyFilter = () => {
    // if (state.mdssIdFilterValues.length + state.patientNameFilterValues.length + state.investigatorFilterValues.length === 3) {
    //     return
    // }
    let count = 0;
    let searchQuerry: {
      MDSS_Id?: Array<String>;
      Patient_id?: Array<String>;
      Investigator_Id?: Array<String>;
      Form_Sent_Date?: Array<String>;
      cache: Boolean;
    } = {
      MDSS_Id: [],

      Patient_id: [],

      Investigator_Id: [],

      Form_Sent_Date: [],
      cache: false,
    };
    if (
      getStateValue.mdssIdFilterValues.length === 0 ||
      getStateValue.mdssIdFilterValues.includes("1")
    )
      delete searchQuerry["MDSS_Id"];
    else {
      searchQuerry["MDSS_Id"] = getStateValue.mdssIdFilterValues;
    }
    if (
      getStateValue.patientNameFilterValues.length === 0 ||
      getStateValue.patientNameFilterValues.includes("1")
    )
      delete searchQuerry["Patient_id"];
    else {
      searchQuerry["Patient_id"] = getStateValue.patientNameFilterValues;
    }
    if (
      getStateValue.investigatorFilterValues.length === 0 ||
      getStateValue.investigatorFilterValues.includes("1")
    )
      delete searchQuerry["Investigator_Id"];
    else {
      searchQuerry["Investigator_Id"] = getStateValue.investigatorFilterValues;
    }
    if (getStateValue.formDate.length === 0)
      delete searchQuerry["Form_Sent_Date"];
    else {
      searchQuerry["Form_Sent_Date"] = getStateValue.formDate;
    }

    searchQuerry["cache"] = false;
    dispatch({
      type: "SetSearchQuerry",
      searchQuerry: searchQuerry,
    });
  };

  useEffect(() => {
    if (isItFirst) {
      setFirst(false);
    } else {
      setisTableDataLoading(true);
      let investigatorsGroupBy: any;
      let TableinvestigatorDetails: any = [];
      refreshData(getStateValue.searchQuerry).then((res) => {
        setTableData(res);
        investigatorsGroupBy = groupBy(res, (i) => {
          return i.Investigator_Id;
        });

        setisTableDataLoading(false);
        for (var key in investigatorsGroupBy) {
          if (investigatorsGroupBy.hasOwnProperty(key)) {
            TableinvestigatorDetails.push({
              ID: key,
              Value: investigatorsGroupBy[key][0].Investigator_Name,
              InvestigatorEmail: investigatorsGroupBy[key][0].InvestigatorEmail,
              ...investigatorsGroupBy[key][0],
            });
          }
        }

        dispatch({
          type: "SETTableInvestigatorFilter",
          TableInvestigatorFilter: TableinvestigatorDetails,
        });
      });
      GetFilters().then((res: any) => {
        let optns = res?.InvestigatorDetails?.map((a: any) => {
          return {
            ID: a.InvestigatorID,
            Value: `${a.Investigator_FirstName} ${a.Investigator_LastName}`,
            ...a,
          };
        });

        setInvestigatorDetails(optns);
      });
    }
  }, [getStateValue.refresh]);

  useEffect(() => {
    setisTableDataLoading(true);
    let investigatorsGroupBy: any;
    let TableinvestigatorDetails: any = [];

    getAllTableData(getStateValue.searchQuerry).then((res) => {
   
      // const result = eachDayOfInterval({
      //   start: new Date(getStateValue.searchQuerry.Form_Sent_Date[0]),
      //   end: new Date(getStateValue.searchQuerry.Form_Sent_Date[1])
      // })
      // console.log(result,":::::::::::")
      let tempTable: any = [];
      res.map((row: any) => {
        let dateDiff = differenceInDays(
          parseISO(row.Form_Sent_Date.value),
          new Date()
        );
       
        const d = new Date(row.Form_Sent_Date.value);
        let day = d.getDay();
        const cudate = new Date();
        const Today = cudate.getDay();
          console.log(Today,"[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[");
          
       if (dateDiff < -4 && row.Form_Status == "Incomplete") {
          // console.log(dateDiff,'datediff')
          // console.log(row.Form_Status)
        } 
        else {
          tempTable.push(row);
        }
      });
    
      //////////////////
      let tempTable2: any = [];
       tempTable.map((row: any) => {
        const d = new Date(row.Form_Sent_Date.value);
        let day = d.getDay();
        const cudate = new Date();
        const Today = cudate.getDay();
        if ((day === 0 || day === 6) && Today === 4 &&row.Form_Status == "Incomplete") {
          // console.log(day);
          console.log(row.Form_Sent_Date.value)
          // console.log(dateDiff,'datediff')
          // console.log(row.Form_Status)
        } else {
          tempTable2.push(row);
        }
      });
      //////////////
      let temp3:any=[];
      tempTable2.map((row:any)=>{
        let Today = new Date().getDay();
        let rowDay =new Date(row.Form_Sent_Date.value).getDay()
        console.log("today is ",Today,"Form date",rowDay)
        if(rowDay ===5  &&  row.Form_Status == "Incompleted" && ( Today===1 || Today ===2 || Today ===3)){

        }
        else if(rowDay ===4  &&  row.Form_Status == "Incompleted" && ( Today===1 || Today ===2 || Today ===3)){

        }else{
          temp3.push(row)
        }
       
      })
      setTableData(temp3);

      investigatorsGroupBy = groupBy(res, (i) => {
        return i.Investigator_Id;
      });

      setisTableDataLoading(false);
      for (var key in investigatorsGroupBy) {
        if (investigatorsGroupBy.hasOwnProperty(key)) {
          TableinvestigatorDetails.push({
            ID: key,
            Value: investigatorsGroupBy[key][0].Investigator_Name,
            InvestigatorEmail: investigatorsGroupBy[key][0].InvestigatorEmail,
            ...investigatorsGroupBy[key][0],
          });
        }
      }

      dispatch({
        type: "SETTableInvestigatorFilter",
        TableInvestigatorFilter: TableinvestigatorDetails,
      });
    });

    GetFilters().then((res: any) => {
      let optns = res?.InvestigatorDetails?.map((a: any) => {
        return {
          ID: a.InvestigatorID,
          Value: `${a.Investigator_FirstName} ${a.Investigator_LastName}`,
          ...a,
        };
      });

      setInvestigatorDetails(optns);
    });
  }, [getStateValue.applyFilter]);
  useEffect(() => {
    isAnyFilter();
  }, [
    getStateValue.mdssIdFilterValues,
    getStateValue.patientNameFilterValues,
    getStateValue.investigatorFilterValues,
    getStateValue.formDate,
  ]);
  return (
    <div className="tableGraph">
      <Modal
        title={"Case Status"}
        centered
        visible={showAllManageCase}
        footer={[
          <Button
            onClick={() => {
              setInprogress(true);
              handleCaseStatus(getStateValue.selectedRows);
            }}
            className="tabButtons"
          >
            OK
          </Button>,
        ]}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <span>
          *Managed in MDSS will be changed if current status is Incomplete or
          Inprocess.{" "}
        </span>

        <Select<string | number, { value: string; children: string }>
          style={{ width: "100%" }}
          placeholder="Select case status"
          optionFilterProp="children"
          value={currentCase}
          disabled
          onChange={(v) => setCurrentCase(v)}
        >
          <Option value="ManagedMdss">Managed in MDSS ID</Option>
        </Select>
      </Modal>
      <Modal
        title={"Change Investigator"}
        centered
        footer={[
          <Button
            onClick={() => {
              setInprogress(true);
              bulkChangeInvestigator(getStateValue.selectedRows);
              handleOk();
            }}
            className="tabButtons"
          >
            OK
          </Button>,
        ]}
        visible={showInvestigator}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <Select<string | number, { value: string; children: string }>
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={(v) => setNewInvestigatorID(v)}
          onSearch={(v) => console.log(`selected ${v}`)}
          filterOption={(input, option) =>
            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {investigatorDetails?.length > 0 &&
            investigatorDetails?.map((investigator: any, i: any) => {
              return (
                <Option key={investigator.ID} value={investigator.ID}>
                  {investigator.Value}
                </Option>
              );
            })}
        </Select>
      </Modal>
      <Tabs
        tabBarExtraContent={slot}
        activeKey={activeKey}
        size="small"
        onChange={(key) => {
          setActiveKey(key);
        }}
        className="float-right"
      >
        <TabPane
          className="tableTab"
          tab={
            <Tooltip placement="bottom" title="Data Table">
              <span className="Tabicon">
                <FaTable />
              </span>
            </Tooltip>
          }
          key="1"
        >
          <AntTable isTableLoading={isTableDataLoading} tableData={tableData} />
        </TabPane>
        <TabPane
          tab={
            <Tooltip placement="bottom" title="Bar Graph">
              <span className="Tabicon">
                <FaChartBar />
              </span>
            </Tooltip>
          }
          key="2"
        >
          <GraphGrid isTableLoading={isTableDataLoading} allData={tableData} />
        </TabPane>
      </Tabs>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        toastClassName="fontMon"
        pauseOnHover
      />
    </div>
  );
};

export default TableGraph;
