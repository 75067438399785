import axios from "axios";

export async function getAllTableData(findDataBody: any = null): Promise<any> {
  let data;
  if (findDataBody === null || findDataBody === "") {
    data = JSON.stringify({});
  } else {
    data = findDataBody;
  }
  let config;

  config = {
    method: "post",
    url: "/api/findQuery",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data.data.filterresult);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
export async function refreshData(findDataBody: any = null): Promise<any> {
  let data;
  if (findDataBody === null || findDataBody === "") {
    data = JSON.stringify({});
  } else {
    data = findDataBody;
  }
  let config;

  config = {
    method: "post",
    url: "/api/refresh",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data.data.filterresult);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
