import axios from "axios";

export async function CheckLogin(token: String): Promise<any> {
  try {
    const res = await axios.post(`/login`, { token: token });
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        console.log("token ->", res.data.token)
        if( res.data.token!==undefined)
        localStorage.setItem("token", res.data.token);
        return resolve(res.data);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
export async function checkToken(URLtoken: String) {
  if (URLtoken) {
    if (loginTemo(URLtoken)) {
      switch (URLtoken) {
        case "superadmin":
          localStorage.setItem("role", "superadmin");
          break;
        case "admin":
          localStorage.setItem("role", "admin");
          break;
        case "user":
          localStorage.setItem("role", "user");
          break;

        default:
          break;
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 2000);
      });
    }
  } else {
    return new Promise((resolve, reject) => {
      reject(false);
    });
  }
}
function loginTemo(urlToken: String) {
  if (urlToken) return true;
  else return false;
}
