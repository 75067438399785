import React, { useEffect } from "react";
import "./GraphGrid.scss";
import Graph from "./graph/Graph";
const GraphGrid = (allData: any) => {
  return (
    <div className="GraphGrid">
      <div className="gridgraph">
        {/*         
        <div className="gridChild">
          <div>5</div>
          <div className="text">Total count</div>
        </div>
  */}
        <div className="graph">
          <Graph graphData={allData} />
        </div>
      </div>
    </div>
  );
};

export default GraphGrid;
