import axios from "axios";

export async function ManagedInMdssApi(record: any): Promise<any> {
  var data = JSON.stringify({
    MDSS_ID: [record.MDSS_Id],
  });
  try {
    var config = {
      method: "post",
      url: "/api/MDSS",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data.data);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
export async function MultiManagedInMdssApi(mdssidArray: any): Promise<any> {
  var data = JSON.stringify({
    MDSS_ID: mdssidArray,
  });
  try {
    var config = {
      method: "post",
      url: "/api/MDSS",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data.data);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
