import React, { Fragment, useEffect, useState } from "react";
import { FiLock } from "react-icons/fi";
import { Loader } from "svg-loader-react";
import { useStateValue } from "../../util/StateProvider";
import "./404.scss";
const FourOFour = () => {
  const [spin, setspin] = useState(true);
  const [getDate, dispatch] = useStateValue();
  useEffect(() => {
    if (!getDate.spin) {
      setspin(false);
    }
  }, [getDate.spin]);
  return (
    <div className="FourOFour">
      {spin ? (
        <div className="loader">
          <Loader name={"audio"} height={40} width={40} color={"#fdb933"} />
        </div>
      ) : (
        <>
          <div>
            <FiLock size={80} />
          </div>
          <br />
          <span className="notFound">
            You are not authorized to access this application
          </span>
          <br />
          <span style={{}}>
            (Please reach out to the administrative staff to resolve this issue)
          </span>
        </>
      )}
    </div>
  );
};

export default FourOFour;
