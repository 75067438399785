import axios from "axios";

export async function sendEducationLinkApi(record: any): Promise<any> {
  try {
    var config = {
      method: "post",
      url: "/api/educationalLink",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        data: {
          MobilePhone: record.Phone,

          PatientName: record.PatientName,

          FormSent: record.Form_Sent_Date.value,

          PatientID: record.Patient_id,

          CaseJurisdiction: record.Jurisdiction_Code,

          InvestigatorEmail: record.InvestigatorEmail,
        },
      },
    };
    const res = await axios(config);
    return await new Promise((resolve, reject) => {
      if (res.status === 200) {
        return resolve(res.data.data);
      } else {
        return reject(res);
      }
    });
  } catch (e) {
    return await new Promise((resolve_1, reject_1) => {
      return reject_1(e);
    });
  }
}
