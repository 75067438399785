import React from "react";
import { useEffect, useState } from "react";
import { Select } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useStateValue } from "../../../util/StateProvider";
import { GetFilters } from "../../../services/filtersDrop";
import "./SelectFilter.scss";

interface MDSSDetails {
  MDSS_Id: String;
  Patient_id: String;
}
interface patientDetails {
  PatientName: String;
  Patient_id: String;
}
interface InvestigatorDetails {
  InvestigatorID: String;
  Investigator_FirstName: String;
  Investigator_LastName: String;
}

interface filter {
  MDSSDetails: Array<MDSSDetails>;
  patientDetails: Array<patientDetails>;
  InvestigatorDetails: Array<InvestigatorDetails>;
}
const SelectFilter = ({ Stateid, allFilterData }: any) => {
  const { Option } = Select;
  const [selectedItems, setselectedItems] = useState<any[]>([]);
  const [allData, setallData] = useState<any[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<
    { ID: any; Value: String }[]
  >([]);
  const [placeHolder, setPlaceHolder] = useState<string>();
  const [getData, dispatch] = useStateValue();

  useEffect(() => {
    if (getData.reset == true) {
      setselectedItems([]);
    }
  }, [getData.reset]);
  useEffect(() => {
    if (Stateid === "mssidFilter") {
      setPlaceHolder("Search MDSS ID");
      let optns = allFilterData?.MDSSDetails?.map((a: MDSSDetails) => {
        return { ID: a.Patient_id, Value: a.MDSS_Id };
      });

      setallData(optns);
      setFilteredOptions(optns?.filter((o: any) => !selectedItems.includes(o)));
    } else if (Stateid === "patientNameFilter") {
      setPlaceHolder("Search Patient Name");
      let optns = allFilterData?.patientDetails?.map((a: patientDetails) => {
        if (a.Patient_id != null)
          return {
            ID: a.Patient_id,
            Value: a.PatientName,
          };
        else {
          return {
            ID: a.PatientName,
            Value: a.PatientName,
          };
        }
      });

      setallData(optns);
      setFilteredOptions(optns?.filter((o: any) => !selectedItems.includes(o)));
    }
  }, []);
  useEffect(() => {
    if (Stateid === "InvestigatorFilter") {
      setPlaceHolder("Search Investigator");

      setallData(getData.TableInvestigatorFilter);
      setFilteredOptions(
        getData.TableInvestigatorFilter?.filter(
          (o: any) => !selectedItems.includes(o)
        )
      );
    }
  }, [getData.TableInvestigatorFilter]);

  useEffect(() => {
    if (allData?.length > 0) {
      setFilteredOptions(allData?.filter((o) => !selectedItems.includes(o)));
    }
  }, [selectedItems]);
  useEffect(() => {
    let localFields = [...selectedItems];
    let storeString: string[] = [];
    let values: String[] = [];
    localFields.map((i) => {
      values.push(i.value);

      return storeString.push(`${i.label}`);
    });

    switch (Stateid) {
      case "mssidFilter":
        dispatch({
          type: "setMdssFilter",
          mdssIdFilter: storeString,
        });
        dispatch({
          type: "SETMDSSVALUE",
          mdssIdFilterValues: storeString,
        });
        break;
      case "patientNameFilter":
        dispatch({
          type: "setPatientFilter",
          patientNameFilter: storeString,
        });
        dispatch({
          type: "SETPatientNameValue",
          patientNameFilterValues: values,
        });
        break;
      case "InvestigatorFilter":
        dispatch({
          type: "setInvestigatorFilter",
          investigatorFilter: storeString,
        });
        dispatch({
          type: "SETInvestigatorFilterValues",
          investigatorFilterValues: values,
        });
        break;
      case "JurisdictionFilter":
        dispatch({
          type: "setJurisdictionFilter",
          JurisdictionFilter: storeString,
        });
        break;

      default:
        break;
    }
  }, [selectedItems, Stateid, dispatch]);
  return (
    <Select
      mode="multiple"
      placeholder={placeHolder}
      value={selectedItems}
      labelInValue
      tokenSeparators={["\n", "\r"]}
      onChange={(e) => {
        dispatch({
          type: "SETRESET",
          reset: false,
        });
        setselectedItems(e);
      }}
      filterOption={(input, option) =>
        option?.lable?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{ width: "400px", fontFamily: "montserrat" }}
    >
      {allData?.map((item, index) => (
        <Select.Option key={item.ID + index} lable={item.Value} value={item.ID}>
          {item.Value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectFilter;
